import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import { PoPurchaseOrderService } from '../purchase-order.service';
import { SendEmailService } from '../../services/send-email.service';

@Component({
  selector: 'app-submit-purchase-order',
  templateUrl: './submit-purchase-order.component.html',
  styleUrls: ['./submit-purchase-order.component.css']
})
export class SubmitPurchaseOrderComponent implements OnInit {

  subscription: Subscription;
  order: any;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private purchaseOrderService: PoPurchaseOrderService,
      private sendEmailService: SendEmailService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
        (params: Params) => {
          const orderId = +params['orderId'];
          this.subscription = this.purchaseOrderService.getOrderForSubmit(orderId)
              .subscribe(
                  (data) => {
                    this.order = data;
                    console.log('this.orderDetail yeah yeah ', this.order);
                  },
                  error => {
                    //
                  }
              );
        }
    );
  }

  loadOrderForSubmit(orderId) {
      this.purchaseOrderService.getOrderForSubmit(orderId)
          .subscribe(
              (data) => {
                  this.order = data;
                  console.log('this.orderDetail yeah yeah ', this.order);
              },
              error => {
                  //
              }
          );
  }

  onClickSendToAdmin(order) {
      this.sendEmailService.sendEmailToAdmin(order)
          .subscribe(
              (data) => {
                  this.loadOrderForSubmit(order.orderId);
              }
          );
  }

  onClickSendToDistributor(order) {
      this.sendEmailService.sendEmailToDistributor(order)
          .subscribe(
              (data) => {
                  this.loadOrderForSubmit(order.orderId);
              }
          );
  }

}
