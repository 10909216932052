import { Component, OnInit } from '@angular/core';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { Observable } from 'rxjs/index';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PromotionService } from '../promotion.service';

@Component({
  selector: 'app-edit-promotion',
  templateUrl: './edit-promotion.component.html',
  styleUrls: ['./edit-promotion.component.css']
})
export class EditPromotionComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  defaultPromotion: any;
  promotion: any;
  promotionId: number;
  today = this.calendar.getToday();

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private calendar: NgbCalendar,
      private promotionService: PromotionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.promotionId = +params['promotionId'];
      this.promotionService.getRecord(this.promotionId)
          .subscribe(
              (data: Data) => {
                this.defaultPromotion = data;
                console.log('this.defaultPromotion', this.defaultPromotion);
                this.defaultPromotion.promotionDateFrom = this.toDate(data.promotionDateFrom);
                this.defaultPromotion.promotionDateTo = this.toDate(data.promotionDateTo);
                this.promotion = Object.assign({}, this.defaultPromotion);
              },
              error => { }
          );
    });
  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  onReset() {
    this.promotion = Object.assign({}, this.defaultPromotion);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {

    } else {
      const dateFormat = 'YYYY-MM-DD';

      const promotionParams = {
        promotionDateFrom: value.promotionDateFrom ? moment([value.promotionDateFrom.year, value.promotionDateFrom.month - 1, value.promotionDateFrom.day]).format(dateFormat) : null,
        promotionDateTo: value.promotionDateTo ? moment([value.promotionDateTo.year, value.promotionDateTo.month - 1, value.promotionDateTo.day]).format(dateFormat) : null,
        promotionSubject: value.promotionSubject,
        promotionDescription: value.promotionDescription,
        promotionRemark: value.promotionRemark,
        promotionActive: value.promotionActive,
      };
      this.promotionService.updateRecord(this.promotionId, promotionParams)
          .subscribe(
              (data) => {
                this.changesSaved = true;
                this.router.navigate(['../../'], {relativeTo: this.route});
              },
              (error) => { }
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultPromotion.promotionCode !== this.promotion.promotionCode
        || this.defaultPromotion.promotionDateFrom !== this.promotion.promotionDateFrom
        || this.defaultPromotion.promotionDateTo !== this.promotion.promotionDateTo
        || this.defaultPromotion.promotionSubject !== this.promotion.promotionSubject
        || this.defaultPromotion.promotionDescription !== this.promotion.promotionDescription
        || this.defaultPromotion.promotionRemark !== this.promotion.promotionRemark
        || this.defaultPromotion.promotionActive !== this.promotion.promotionActive) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }

}
