import {EventEmitter, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  dataChanged = new EventEmitter<any[]>();
  //private rootUrl = 'http://127.0.0.1:8000/api/admin/email';
  protected rootUrl = 'http://td-order-be.r2omm.xyz/api/admin/email';

  constructor(private httpClient: HttpClient,
              private messageService: MessageService) {
  }

  displayMessages(messageParam, successful) {
    const message = (typeof messageParam === 'string') ? messageParam : messageParam.join('<br>');
    // const alertType = successful ? 'alert-success' : 'alert-danger';
    // this.flashMessagesService.show(message, {cssClass: alertType, timeout: 5000});
    if (successful) {
      this.messageService.success(message);
    } else {
      this.messageService.error(message);
    }
  }

  sendEmailToDistributor(resource) {
    const myUrl = this.rootUrl + '/send/distributor';
    return this.httpClient.post(myUrl, resource)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;
        }));
  }

  sendEmailToAdmin(resource) {
      const myUrl = this.rootUrl + '/send/admin';
      return this.httpClient.post(myUrl, resource)
          .pipe(map((data: any) => {
              // const data = response.json();
              this.displayMessages(data.messages, true);
              this.dataChanged.emit(data.data);
              return data.data;
          }));
    }
}
