import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { OtcSalesAreaService } from './otc-sales-area.service';

interface OtcSalesArea {
  otcSalesAreaId: number;
  otcSalesAreaName: string;
}

@Injectable()
export class OtcSalesAreaResolver implements Resolve<OtcSalesArea[]> {
  constructor(private otcSalesAreaService: OtcSalesAreaService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OtcSalesArea[]> | Promise<OtcSalesArea[]> | OtcSalesArea[] {
    return this.otcSalesAreaService.getRecords();
  }
}
