import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { MessageService } from '../message.service';
import { catchError } from 'rxjs/internal/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PharmaHospitalService extends DataService {

  constructor(httpClient: HttpClient,
              messageService: MessageService) {
    super(httpClient, 'hospital', messageService);
  }

  getHospitalDeliveries(hospitalId) {
    const myUrl = this.baseUrl + '/' + hospitalId + '/deliveries';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  getPaginatedDataForOrder(conditions) {
    const myUrl = this.baseUrl + '/otc/order/paginate';
    return this.httpClient.post(myUrl, conditions)
        .pipe(map(
            (data: any) => {
                return data;
            }
        ), catchError((errorData: any) => {
            return this.handleError(errorData);
        }));
  }
}



