import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, Data } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { PoPurchaseOrderService } from '../purchase-orders/purchase-order.service';
import { PurchaseOrderDetailService } from './purchase-order-detail.service';

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.css']
})
export class PurchaseOrderDetailsComponent implements OnInit {

  orderId: number;
  order: any;
  orderDetails: any[] = [];
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private purchaseOrderService: PoPurchaseOrderService,
      private purchaseOrderDetailService: PurchaseOrderDetailService,
  ) { }

  ngOnInit() {
    // this.route.params.subscribe(
    //     (params: Params) => {
    //       this.orderId = +params['orderId'];
    //       const currentUrl = this.router.url;
    //
    //       this.loadPurchaseOrder(this.orderId);
    //
    //       if (currentUrl.indexOf('details') >= 0) {
    //
    //         this.subscription = this.purchaseOrderDetailService.getRecords(this.orderId)
    //             .subscribe(
    //                 (data) => {
    //                   this.orderDetails = data;
    //                 },
    //                 error => {
    //                   //
    //                 }
    //             );
    //       }
    //     }
    // );
    //
    // this.purchaseOrderDetailService.dataChanged.subscribe(
    //     (data: Data) => {
    //       this.purchaseOrderDetailService.getRecords(this.orderId)
    //           .subscribe(
    //               (orderDetails: any[]) => {
    //                 this.orderDetails = orderDetails;
    //               },
    //               error => {
    //                 //
    //               }
    //           );
    //     }
    // );
  }

  loadPurchaseOrder(orderId) {
      // this.purchaseOrderService.getRecord(orderId)
      //     .subscribe(
      //         (order: any[]) => {
      //             this.order = order;
      //         },
      //         error => {
      //
      //         }
      //     );
  }
}
