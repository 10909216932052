import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class ComboService extends DataService {

  constructor(httpClient: HttpClient, messageService: MessageService) {
    super(httpClient, 'combo', messageService);

  }
}
