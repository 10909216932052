import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {

  transform(value: any, filterString: any, propertyName: string): any {
    if (value.length === 0 ||  !filterString ) {
      return value;
    }
    const resultArray = [];
    for (const item of value) {
      const itemValue = item[propertyName].toLowerCase();
      const filterStringValue = filterString.toLowerCase();
      if (itemValue.includes(filterStringValue)) {
        resultArray.push(item);

      }
    }
    return resultArray;
  }

}
