import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { OrderStatusService } from './order-status.service';

interface OrderStatus {
  orderStatusId: number;
  orderStatusNameEng: string;
  orderStatusNameVie: string;
  orderStatusRemark: string;
}

@Injectable()

export class OrderStatusesResolver implements Resolve<OrderStatus[]> {
  constructor(private orderStatusService: OrderStatusService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrderStatus[]> | Promise<OrderStatus[]> | OrderStatus[] {
    return this.orderStatusService.getRecords();
  }
}
