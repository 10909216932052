import {EventEmitter, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDeliveryService {
  dataChanged = new EventEmitter<any[]>();
  //private rootUrl = 'http://127.0.0.1:8000/api/admin/hospital';
  protected rootUrl = 'http://td-order-be.r2omm.xyz/api/admin/hospital';

  constructor(private httpClient: HttpClient,
              private messageService: MessageService) {
  }

  displayMessages(messageParam, successful) {
    const message = (typeof messageParam === 'string') ? messageParam : messageParam.join('<br>');
    // const alertType = successful ? 'alert-success' : 'alert-danger';
    // this.flashMessagesService.show(message, {cssClass: alertType, timeout: 5000});
    if (successful) {
      this.messageService.success(message);
    } else {
      this.messageService.error(message);
    }
  }

  createRecord(resource) {
    const myUrl = this.rootUrl + '/' + resource.hospitalId + '/delivery';
    return this.httpClient.post(myUrl, resource)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;
        }));
  }


  updateRecord(hospitalId, deliveryId, resource) {
    const myUrl = this.rootUrl + '/' + hospitalId + '/delivery/' + deliveryId;
    return this.httpClient.put(myUrl, resource)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;

        }));
  }

  deleteRecord(hospitalId, deliveryId) {
    const myUrl = this.rootUrl + '/' + hospitalId + '/delivery/' + deliveryId;
    return this.httpClient.delete(myUrl)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;

        }));
  }

  getRecord(hospitalId, deliveryId) {
    const myUrl = this.rootUrl + '/' + hospitalId + '/delivery/' + deliveryId;
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }

  editRecord(hospitalId, deliveryId) {
    const myUrl = this.rootUrl + '/' + hospitalId + '/delivery/' + deliveryId + '/edit';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }

  getRecords(hospitalId) {
    const myUrl = this.rootUrl + '/' + hospitalId + '/delivery';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }
}
