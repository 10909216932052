import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { PromotionDetailService } from '../../promotion-details/promotion-detail.service';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.css']
})
export class PromotionDetailComponent implements OnInit, OnDestroy {

  promotionDetail: any = [];
  subscription: Subscription;
  promotionId: number;
  promotionDetailId: number;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private promotionDetailService: PromotionDetailService,
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe(
        (paramsParent: Params) => {
          this.promotionId = +paramsParent['promotionId'];

          this.route.params.subscribe(
              (paramsChild: Params) => {
                  this.promotionDetailId = +paramsChild['promotionDetailId'];

                  this.subscription = this.promotionDetailService.getRecord(this.promotionId, this.promotionDetailId)
                      .subscribe(
                          (data) => {
                              this.promotionDetail = data;
                          },
                          error => {}
                      );
              }
          );
        }
    );
  }

  onClickDelete(record) {
      if (confirm('Are you sure you want to delete this promotion?')) {
          this.promotionDetailService.deleteRecord(record.promotionId, record.promotionDetailId)
              .subscribe(
                  (data) => {
                      this.router.navigate(['../'], {relativeTo: this.route});
                  },
                  error => {}
              );
      }
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

}
