import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { PromotionProductService } from './promotion-product.service';

interface PromotionProduct {
  promotionProductId: number;
  promotionProductName: string;
}

@Injectable()
export class PromotionProductResolver implements Resolve<PromotionProduct[]> {
  constructor(private promotionProductService: PromotionProductService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PromotionProduct[]> | Promise<PromotionProduct[]> | PromotionProduct[] {
    return this.promotionProductService.getRecords();
  }
}
