import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class ComboProductService {

  dataChanged = new EventEmitter<any[]>();
  // private rootUrl = 'http://127.0.0.1:8000/api/admin/combo';
  protected rootUrl = 'http://td-order-be.r2omm.xyz/api/admin/combo';

  constructor(private httpClient: HttpClient,
              private messageService: MessageService) {
  }

  displayMessages(messageParam, successful) {
    const message = (typeof messageParam === 'string') ? messageParam : messageParam.join('<br>');
    // const alertType = successful ? 'alert-success' : 'alert-danger';
    // this.flashMessagesService.show(message, {cssClass: alertType, timeout: 5000});
    if (successful) {
      this.messageService.success(message);
    } else {
      this.messageService.error(message);
    }
  }

  createRecord(comboId, resource) {
    const myUrl = this.rootUrl + '/' + comboId + '/product';
    return this.httpClient.post(myUrl, resource)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;
        }));
  }

  updateRecord(comboId, comboProductId, resource) {
    const myUrl = this.rootUrl + '/' + comboId + '/product/' + comboProductId;
    return this.httpClient.put(myUrl, resource)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;

        }));
  }

  deleteRecord(comboId, comboProductId) {
    const myUrl = this.rootUrl + '/' + comboId + '/product/' + comboProductId;
    return this.httpClient.delete(myUrl)
        .pipe(map((data: any) => {
          // const data = response.json();
          this.displayMessages(data.messages, true);

          this.dataChanged.emit(data.data);
          return data.data;

        }));
  }

  getRecord(comboId, comboProductId) {
    const myUrl = this.rootUrl + '/' + comboId + '/product/' + comboProductId;
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }

  editRecord(comboId, comboProductId) {
    const myUrl = this.rootUrl + '/' + comboId + '/product/' + comboProductId + '/edit';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }

  getRecords(comboId) {
    const myUrl = this.rootUrl + '/' + comboId + '/product';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              // const data = response.json();
              return data.data;
            }
        ));
  }
}
