import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { MessageService } from '../message.service';
import { catchError } from 'rxjs/internal/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeoProvinceService extends DataService {

  constructor(httpClient: HttpClient,
              messageService: MessageService) {
    super(httpClient, 'province', messageService);
  }

  getOtcCoveredProvinces() {
    const myUrl = this.baseUrl + '/otc';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

}



