import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PoPurchaseOrderService } from '../purchase-order.service';

@Component({
  selector: 'app-cancel-purchase-order',
  templateUrl: './cancel-purchase-order.component.html',
  styleUrls: ['./cancel-purchase-order.component.css']
})
export class CancelPurchaseOrderComponent implements OnInit {
  @Input() orderId;
  defaultOrder: any = {
    dateConfirmedOrderCancellation: null
  };
  order: any = Object.assign({}, this.defaultOrder);
  today = this.calendar.getToday();

  constructor(
      public activeModal: NgbActiveModal,
      private calendar: NgbCalendar,
      private purchaseOrderService: PoPurchaseOrderService,
  ) { }

  ngOnInit() {
    this.getOrder(this.orderId);
  }

  getOrder(orderId) {
    // this.order = [];
    this.purchaseOrderService.getRecord(orderId)
        .subscribe(
            (result: any) => {
              this.order = result;
              this.order.dateConfirmedOrderCancellation = this.toDate(result.dateConfirmedOrderCancellation);

              this.defaultOrder = Object.assign({}, this.order);
              console.log('this.order abc', this.order);
            },
            error => { }
        );
  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  onConfirmOrderCancellation(order, orderId) {
    const order_cancel = {
      dateConfirmedOrderCancellation : order.dateConfirmedOrderCancellation ? moment([order.dateConfirmedOrderCancellation.year, order.dateConfirmedOrderCancellation.month - 1, order.dateConfirmedOrderCancellation.day]).format('YYYY-MM-DD') : null,
      orderCancellationReason: order.orderCancellationReason,
    }
    this.purchaseOrderService.confirmOrderCancellation(order_cancel, orderId)
        .subscribe(
            (data) => {},
            (error) => {}
        );
  }

  onCancelOrderCancellation(order, orderId) {
    this.purchaseOrderService.cancelOrderCancellation(order, orderId)
        .subscribe(
            (data) => {},
            (error) => {}
        );
  }

  onReset() {
    console.log('this.defaultOrder reset', this.defaultOrder);
    this.order = Object.assign({}, this.defaultOrder);
    console.log('this.order reset', this.order);
  }

}
