import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
      // private flashMessagesService: FlashMessagesService,
      private toastrService: ToastrService
  ) { }

  success(message: string) {
    // this.flashMessagesService.show(message, {cssClass: 'alert-success flashfade', timeout: 5000});
    this.toastrService.success(message);
  }
  error(message: string) {
    // this.flashMessagesService.show(message, {cssClass:'alert-danger flashfade', timeout: 5000});
    this.toastrService.error(message);
  }

  info(message: string) {
    // this.flashMessagesService.show(message, {cssClass: 'alert-info flashfade', timeout: 5000});
    this.toastrService.info(message);
  }
}
