import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { ComboService } from '../../combos/combo.service';

@Component({
  selector: 'app-combo-list',
  templateUrl: './combo-list.component.html',
  styleUrls: ['./combo-list.component.css']
})
export class ComboListComponent implements OnInit {
  
  combos: any[] = [];
  itemsPerPage = 5;
  totalItems: any;
  page: any = 1;
  previousPage: any;
  sortBy = '-tbl_PoCombo.dtCreated';
  filteredData = {
    comboSubject: ''
  };

  constructor(
      private comboService: ComboService,
  ) { }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData(false);
    }
  }

  loadData(withFilteredData: boolean) {
    const conditions = {
      sort: this.sortBy,
      filter: this.filteredData,
      page: withFilteredData ? 1 : this.page,
      limit: this.itemsPerPage
    };

    this.comboService.getPaginatedData(conditions)
        .subscribe(
            (data: any) => {
              this.combos = data.data;
              this.totalItems = data.paginator.total_count;
            },
            error => { }
        );
  }

  ngOnInit() {
    this.loadData(false);

    this.comboService.dataChanged.subscribe((data: Data) => {
      this.loadData(false);
    });
  }

}
