import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appRemoveSpace]'
})
export class RemoveSpaceDirective {

  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef) { }

  @HostListener('blur') onBlur() {
    const value = this.el.nativeElement.value;
    this.el.nativeElement.value = value.replace(/\s/g, '');
    this.ngModelChange.emit(this.el.nativeElement.value);
  }

}
