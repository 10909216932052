import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { PurchaseOrderDetailService } from '../purchase-order-detail.service';


@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.css']
})
export class PurchaseOrderDetailComponent implements OnInit {

  orderDetail: any;
  subscription: Subscription;
  orderId: number;

  constructor(
      private purchaseOrderDetailService: PurchaseOrderDetailService,
      private route: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe(
        (params: Params) => {
          this.orderId = +params['orderId'];
        }
    );
    this.route.params.subscribe(
        (params: Params) => {
          const orderDetailId = +params['orderDetailId'];
          this.subscription = this.purchaseOrderDetailService.getRecord(this.orderId, orderDetailId)
              .subscribe(
                  (data) => {
                    this.orderDetail = data;
                    console.log('this.orderDetail yeah yeah ', this.orderDetail);
                  },
                  error => {
                    //
                  }
              );
        }
    );
  }

  onClickDelete(record) {
      if (confirm('Are you sure you want to delete this detail?')) {
          this.purchaseOrderDetailService.deleteRecord(this.orderId, record.orderDetailId)
              .subscribe(
                  (data) => {
                      this.router.navigate(['../'], {relativeTo: this.route});
                  },
                  error => {
                      //
                  }
              );
      }
  }

}
