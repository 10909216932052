import { Component, OnInit } from '@angular/core';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { Observable } from 'rxjs/index';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PromotionDetailService } from '../promotion-detail.service';
import { PharmaProductService } from '../../services/pharma-product.service';

@Component({
  selector: 'app-edit-promotion-detail',
  templateUrl: './edit-promotion-detail.component.html',
  styleUrls: ['./edit-promotion-detail.component.css']
})
export class EditPromotionDetailComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  defaultPromotionDetail: any;
  promotionDetail: any;
  promotionId: number;
  promotionDetailId: number;
  products: any = [];

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private calendar: NgbCalendar,
      private promotionDetailService: PromotionDetailService,
      private productService: PharmaProductService,
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((parentParams: Params) => {
        this.promotionId = +parentParams['promotionId'];
        this.route.params.subscribe((childParams: Params) => {
          this.promotionDetailId = +childParams['promotionDetailId'];
          this.promotionDetailService.getRecord(this.promotionId, this.promotionDetailId)
              .subscribe(
                  (data: Data) => {
                    this.defaultPromotionDetail = data;
                    this.defaultPromotionDetail.promotionValidFromDate = this.toDate(data.promotionValidFromDate);
                    this.defaultPromotionDetail.promotionValidToDate = this.toDate(data.promotionValidToDate);
                    this.promotionDetail = Object.assign({}, this.defaultPromotionDetail);

                    this.loadOtcCoveredProducts();
                  },
                  error => { }
              );
        });
    });
  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  loadOtcCoveredProducts() {
    this.productService.getProductOtcCovered()
        .subscribe(
            (products: any[]) => {
              this.products = products;
            },
            error => {}
        );
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {

    } else {
      const dateFormat = 'YYYY-MM-DD';

      const promotionDetailParams = {
        productId: value.productId,
        promotionValidFromDate: value.promotionValidFromDate ? moment([value.promotionValidFromDate.year, value.promotionValidFromDate.month - 1, value.promotionValidFromDate.day]).format(dateFormat) : null,
        promotionValidToDate: value.promotionValidToDate ? moment([value.promotionValidToDate.year, value.promotionValidToDate.month - 1, value.promotionValidToDate.day]).format(dateFormat) : null,
        promotionDetailRemark: value.promotionDetailRemark,
        promotionDetailActive: value.promotionDetailActive,
      };
      this.promotionDetailService.updateRecord(this.promotionId, this.promotionDetailId, promotionDetailParams)
          .subscribe(
              (data) => {
                this.changesSaved = true;
                this.router.navigate(['../'], {relativeTo: this.route});
              },
              (error) => { }
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultPromotionDetail.productId !== this.promotionDetail.productId
        || this.defaultPromotionDetail.promotionValidFromDate !== this.promotionDetail.promotionValidFromDate
        || this.defaultPromotionDetail.promotionValidToDate !== this.promotionDetail.promotionValidToDate
        || this.defaultPromotionDetail.promotionDetailRemark !== this.promotionDetail.promotionDetailRemark
        || this.defaultPromotionDetail.promotionDetailActive !== this.promotionDetail.promotionDetailActive) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }
}
