import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, Data } from '@angular/router';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { Observable } from 'rxjs/index';
import * as moment from 'moment';
import { PromotionService } from '../../promotions/promotion.service';
import { PromotionDetailService } from '../../promotion-details/promotion-detail.service';
import { PharmaProductService } from '../../services/pharma-product.service';

@Component({
  selector: 'app-create-promotion-detail',
  templateUrl: './create-promotion-detail.component.html',
  styleUrls: ['./create-promotion-detail.component.css']
})
export class CreatePromotionDetailComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  promotionId: number;
  promotion: any = [];
  promotionDetail: any;
  products: any = [];

  defaultPromotionDetail: any = {
    promotionId: '',
    productId: '',
    promotionDetailActive: 1,
    promotionValidFromDate: '',
    promotionValidToDate: '',
  }

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private promotionService: PromotionService,
      private promotionDetailService: PromotionDetailService,
      private productService: PharmaProductService,
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe(
        (params: Params) => {
          this.promotionId = +params['promotionId'];
          this.promotionService.getRecord(this.promotionId)
              .subscribe(
                  (data) => {
                    this.promotion = data;
                    this.defaultPromotionDetail.promotionValidFromDate = this.toDate(data.promotionDateFrom);
                    this.defaultPromotionDetail.promotionValidToDate = this.toDate(data.promotionDateTo);
                    this.defaultPromotionDetail.promotionDetailActive = data.promotionActive;

                    this.promotionDetail = Object.assign({}, this.defaultPromotionDetail);
                  }
              );
        }
    );
    this.loadProductOtcCovered();
  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  loadProductOtcCovered() {
    this.productService.getProductOtcCovered()
        .subscribe(
            (products: any[]) => {
              this.products = products;
            },
            error => {}
        );
  }

  onReset() {
      this.promotionDetail = Object.assign({}, this.defaultPromotionDetail);
  }

  onClickCancel() {
      this.changesSaved = true;
      this.router.navigate(['../'], { relativeTo: this.route });
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      //
    } else {
      const dateFormat = 'YYYY-MM-DD';
      const promotionDetail  = {
        promotionId: this.promotionId,
        productId: value.productId,
        promotionValidFromDate: value.promotionValidFromDate ? moment([value.promotionValidFromDate.year, value.promotionValidFromDate.month - 1, value.promotionValidFromDate.day]).format(dateFormat) : null,
        promotionValidToDate: value.promotionValidToDate ? moment([value.promotionValidToDate.year, value.promotionValidToDate.month - 1, value.promotionValidToDate.day]).format(dateFormat) : null,
        promotionDetailRemark: value.promotionDetailRemark,
        promotionDetailActive: value.promotionDetailActive,
      };
      this.promotionDetailService.createRecord(this.promotionId, promotionDetail)
          .subscribe(
              (data) => {
                this.changesSaved = true;
                this.router.navigate(['../'], {relativeTo: this.route});
              },
              (error) => {}
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if ((this.defaultPromotionDetail.productId !== this.promotionDetail.productId
          || this.defaultPromotionDetail.promotionValidFromDate !== this.promotionDetail.promotionValidFromDate
          || this.defaultPromotionDetail.promotionValidToDate !== this.promotionDetail.promotionValidToDate
          || this.defaultPromotionDetail.promotionDetailRemark !== this.promotionDetail.promotionDetailRemark
          || this.defaultPromotionDetail.promotionDetailActive !== this.promotionDetail.promotionDetailActive) && !this.changesSaved) {
          return confirm('Do you want to leave this page and discard the changes?');
      } else {
          return true;
      }
  }

}
