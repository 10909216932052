import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params, Data  } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { ComboProductService } from './combo-product.service';

@Component({
  selector: 'app-combo-products',
  templateUrl: './combo-products.component.html',
  styleUrls: ['./combo-products.component.css']
})
export class ComboProductsComponent implements OnInit, OnDestroy {

  comboProducts: any[] = [];
  comboId: number;
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private comboProductService: ComboProductService,
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((params: Params) => {
      this.comboId = +params['comboId'];

      this.subscription = this.comboProductService.getRecords(this.comboId).subscribe((data) => {
        this.comboProducts = data;
        console.log('this.comboProducts', this.comboProducts);
      }, error => {});
    });

    this.comboProductService.dataChanged.subscribe((data: Data) => {
      this.comboProductService.getRecords(this.comboId).subscribe((products: any[]) => {
        this.comboProducts = products;
        console.log('this.comboProducts', this.comboProducts);
      }, error => {});
    });
  }

  onClickDelete(record) {
    if (confirm('Are you sure you want to delete this combo product?')) {
      this.comboProductService.deleteRecord(record.comboId, record.comboProductId)
          .subscribe(
              (data) => {},
              error => {}
          );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
