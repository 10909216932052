import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || value === '' || value === '0000-00-00' || value === '0000-00-00 00:00:00') {
      return null;
    } else if (moment(value, 'YYYY-MM-DD').isSame(moment(), 'day')) {
      return 'today';
    } else {
      return moment(value, 'YYYY-MM-DD').fromNow();
    }

  }

}
