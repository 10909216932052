import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-purchase-order-detail-combo',
  templateUrl: './create-purchase-order-detail-combo.component.html',
  styleUrls: ['./create-purchase-order-detail-combo.component.css']
})
export class CreatePurchaseOrderDetailComboComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
