import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: any = {
    username: '',
    password: ''
  };
  constructor(
      private router: Router,
      private authService: AuthService,
      private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }){
    if (!valid) {
      this.messageService.info('Please fill username and password');
    } else {
      this.authService.login(value)
          .subscribe((data) => {
            console.log('successfull login returned data...', data);
            this.router.navigate(['/home']);
            this.messageService.success('You are successfully logged in.');
          },
          (error) => {
            console.log('failed login returned error...', error);
            const message = error.error.message ? error.error.message : 'Unexpected error occurred. Please try again!';
            this.messageService.error(message);
            this.user.password = '';
          });
    }
  }

}
