import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {Injectable} from '@angular/core';
import {AuthService} from '../login/auth.service';
import {throwError} from 'rxjs/index';
import {catchError} from 'rxjs/internal/operators';
import {map, flatMap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private httpClient: HttpClient,
                private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('intercepted url...', req.url);
        if (req.url.search('oauth/token') === -1 && req.url.search('publicapp') === -1) {
            const copiedReq = req.clone(this.authService.tokenHeader);
            console.log('intercepted and appended token header...', copiedReq);
            return next.handle(copiedReq).pipe(
                map((event: any) => {
                    return event;
                }), catchError((errorBefRefreshToken: any) => {
                    console.log('error before refreshing token...', errorBefRefreshToken);
                    console.log(`${errorBefRefreshToken.status} ${errorBefRefreshToken.statusText} -  ${errorBefRefreshToken.url}`);
                    if (errorBefRefreshToken.status === 401) {
                        return this.httpClient.post('http://127.0.0.1:8000/oauth/token', this.authService.refreshResource, {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            })
                        }).pipe(flatMap((data: any) => {
                            console.log('refreshing token response data...', data);
                            this.authService.authenticate(data);
                            const copiedReq2 = req.clone(this.authService.tokenHeader);
                            console.log('intercepted and re-appended token header after refreshing token...', copiedReq2);
                            return next.handle(copiedReq2);
                        }), catchError((errorAfterRefreshToken: any) => {
                            console.log('error after refreshing token...', errorAfterRefreshToken);
                            this.authService.logout();
                            return throwError(errorAfterRefreshToken);
                        }));
                    }
                    return throwError(errorBefRefreshToken);
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
