import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';
import { throwError } from 'rxjs/index';
import { MessageService } from './message.service';

export class DataService {

  //protected rootUrl = 'http://127.0.0.1:8000/api/';
  protected rootUrl = 'http://td-order-be.r2omm.xyz/api/';
  protected baseUrl = '';
  protected publicUrl = '';
  public dataChanged = new EventEmitter<any[]>();

  constructor(protected httpClient: HttpClient,
              private childUrl: string,
              protected messageService: MessageService) {
    this.baseUrl = this.rootUrl + 'admin/' + childUrl;
    this.publicUrl = this.rootUrl + 'publicapp/' + childUrl;
  }

  protected displayMessages(messageParam, successful) {
    console.log('preparing to display messages...', messageParam);
    const message = (typeof messageParam === 'string') ? messageParam : messageParam.join('<br>');
    console.log('message', message);
    if (successful) {
      this.messageService.success(message);
    } else {
      this.messageService.error(message);
    }

  }

  protected handleError(errorData: any) {
    console.log('error data returned...', errorData);
    const genErrMsg = 'Unexpected error occured. Please try again!';
    this.displayMessages(errorData.error.messages || genErrMsg, false);
    return throwError(errorData.error.errors);
  }

  getRecords(publicUrl?: boolean) {
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl);
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  getPaginatedData(conditions) {
    const myUrl = this.baseUrl + '/paginate';
    return this.httpClient.post(myUrl, conditions)
        .pipe(map(
            (data: any) => {
              return data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  getRecord(id: number, publicUrl?: boolean) {
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl) + '/' + id;
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  editRecord(id: number, publicUrl?: boolean) {
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl) + '/' + id + '/edit';
    return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  createRecord(resource: any, publicUrl?: boolean) {
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl);
    return this.httpClient.post(myUrl, resource)
        .pipe(map((data: any) => {
          this.displayMessages(data.messages, true);
          this.dataChanged.emit(data.data);
          console.log('successfully created record...', data.data);
          return data.data;
        }), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  updateRecord(id: number, resource: any, publicUrl?: boolean) {
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl) + '/' + id;
    return this.httpClient.put(myUrl, resource)
        .pipe(map((data: any) => {
          this.displayMessages(data.messages, true);
          this.dataChanged.emit(data.data);
          return data.data;

        }), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  deleteRecord(id: number, publicUrl?: boolean) {
    console.log(publicUrl);
    const myUrl = (publicUrl ? this.publicUrl : this.baseUrl) + '/' + id;
    return this.httpClient.delete(myUrl)
        .pipe(map((data: any) => {
          this.displayMessages(data.messages, true);
          this.dataChanged.emit(data.data);
          return data.data;
        }), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }
}
