import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[app-promotion-detail-item]',
  templateUrl: './promotion-detail-item.component.html',
  styleUrls: ['./promotion-detail-item.component.css']
})
export class PromotionDetailItemComponent implements OnInit {

  @Input() promotionDetail: any;
  @Input() index: number;
  constructor() { }

  ngOnInit() {
  }

}
