import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  onLogoutClick() {
    this.authService.logout();
    this.messageService.success('You are successfully logged out');
  }
}
