import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ComboService } from '../combo.service';

@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.css']
})
export class ComboComponent implements OnInit, OnDestroy {

  combo: any;
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private comboService: ComboService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const comboId = +params['comboId'];
      this.subscription = this.comboService.getRecord(comboId).subscribe((data) => {
        this.combo = data;
      }, error => {});
    });
  }

  onClickDelete(record) {
    if (confirm('Are you sure you want to delete this combo?')) {
      this.comboService.deleteRecord(record.comboId)
          .subscribe(
              (data: any) => {
                this.router.navigate(['../'], {relativeTo: this.route});
              },
              error => {
                //
              }
          );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
