import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/index';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import * as moment from 'moment';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, Data, Params } from '@angular/router';
import { ComboService } from '../combo.service';


@Component({
  selector: 'app-edit-combo',
  templateUrl: './edit-combo.component.html',
  styleUrls: ['./edit-combo.component.css']
})
export class EditComboComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  changesSaved: boolean = false;
  defaultCombo: any;
  combo: any;
  comboId: number;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private calendar: NgbCalendar,
      private comboService: ComboService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.comboId = +params['comboId'];
      this.comboService.getRecord(this.comboId).subscribe((data: Data) => {
        this.defaultCombo = data;
        this.defaultCombo.comboValidFromDate = this.toDate(data.comboValidFromDate);
        this.defaultCombo.comboValidToDate = this.toDate(data.comboValidToDate);
        this.combo = Object.assign({}, this.defaultCombo);
      }, error => {});
    });

  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  onReset() {
    this.combo = Object.assign({}, this.defaultCombo);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
    } else {
      const dateFormat = 'YYYY-MM-DD';
      const comboParams = {
        comboSubject: value.comboSubject,
        comboDescription: value.comboDescription,
        comboValidFromDate: value.comboValidFromDate ? moment([value.comboValidFromDate.year, value.comboValidFromDate.month - 1, value.comboValidFromDate.day]).format(dateFormat) : null,
        comboValidToDate: value.comboValidToDate ? moment([value.comboValidToDate.year, value.comboValidToDate.month - 1, value.comboValidToDate.day]).format(dateFormat) : null,
        comboRemark: value.comboRemark,
        comboActive: value.comboActive
      }
      this.comboService.updateRecord(this.comboId, comboParams).subscribe((data) => {
        this.changesSaved = true;
        this.router.navigate(['../'], { relativeTo: this.route });
      }, (error) => {});
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultCombo.comboSubject !== this.combo.comboSubject
        || this.defaultCombo.comboDescription !== this.combo.comboDescription
        || this.defaultCombo.comboValidFromDate !== this.combo.comboValidFromDate
        || this.defaultCombo.comboValidToDate !== this.combo.comboValidToDate
        || this.defaultCombo.comboActive !== this.combo.comboActive) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    //
  }

}
