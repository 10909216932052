import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { PromotionService } from '../promotion.service';
import { Observable } from 'rxjs/index';


@Component({
  selector: 'app-create-promotion',
  templateUrl: './create-promotion.component.html',
  styleUrls: ['./create-promotion.component.css']
})
export class CreatePromotionComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  today = this.calendar.getToday();
  errors: string[] = [];

  defaultPromotion: any = {
    promotionCode: '',
    promotionDateFrom: '',
    promotionDateTo: '',
    promotionSubject: '',
    promotionDetail: '',
    promotionRemark: '',
    promotionActive: 1,
  };
  promotion: any = Object.assign({}, this.defaultPromotion);

  constructor(
      private calendar: NgbCalendar,
      private route: ActivatedRoute,
      private router: Router,
      private promotionService: PromotionService,
  ) { }

  ngOnInit() {
  }

  onReset() {
    this.promotion = Object.assign({}, this.defaultPromotion);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      //
    } else {
      const dateFormat = 'YYYY-MM-DD';
      const promotion  = {
        promotionDateFrom: value.promotionDateFrom ? moment([value.promotionDateFrom.year, value.promotionDateFrom.month - 1, value.promotionDateFrom.day]).format(dateFormat) : null,
        promotionDateTo: value.promotionDateTo ? moment([value.promotionDateTo.year, value.promotionDateTo.month - 1, value.promotionDateTo.day]).format(dateFormat) : null,
        promotionSubject: value.promotionSubject,
        promotionDescription: value.promotionDescription,
        promotionRemark: value.promotionRemark,
        promotionActive: value.promotionActive,
      };
      this.promotionService.createRecord(promotion)
          .subscribe(
              (data) => {
                this.changesSaved = true;
                this.router.navigate(['../'], {relativeTo: this.route});
              },
              (error) => {}
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultPromotion.promotionCode !== this.promotion.promotionCode
        || this.defaultPromotion.promotionDateFrom !== this.promotion.promotionDateFrom
        || this.defaultPromotion.promotionDateTo !== this.promotion.promotionDateTo
        || this.defaultPromotion.promotionSubject !== this.promotion.promotionSubject
        || this.defaultPromotion.promotionDescription !== this.promotion.promotionDescription
        || this.defaultPromotion.promotionRemark !== this.promotion.promotionRemark
        || this.defaultPromotion.promotionActive !== this.promotion.promotionActive) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }

}
