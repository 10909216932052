import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../common/sortable.directive';
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

import { PromotionService } from './promotion.service';
import {load} from "@angular/core/src/render3";

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

  defaultPromotions: any[] = [];
  promotions: any[] = [];

  itemsPerPage = 10;
  totalItems: any;
  page: any = 1;
  previousPage: any;
  sortBy = 'tbl_PoPurchaseOrder.orderCode';
  sortOrder = 'asc';

  emptyFilteredData = {
    orderPeriodType: 'date',
    orderDateFrom: this.calendar.getToday(),
    orderDateTo: this.calendar.getToday(),
    orderDateFrom_f: '',
    orderDateTo_f: '',
    orderStatusId: '',
    hospitalName: '',
    hospitalAddress: '',
  };
  filteredData = Object.assign({}, this.emptyFilteredData);

  constructor(
      private calendar: NgbCalendar,
      private modalService: NgbModal,
      private promotionService: PromotionService,
      private route: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit() {
    this.loadData(true);
  }

  loadData(withFilteredData: boolean) {

    const conditions = {
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      filter: this.filteredData,
      page: withFilteredData ? 1 : this.page,
      limit: this.itemsPerPage,
    };

    this.promotionService.getPaginatedData(conditions)
        .subscribe(
            (data: any) => {
              this.promotions = data.data;
              this.totalItems = data.paginator.total_count;
              console.log('this.promotions', this.promotions);
            },
            error => {
              //
            }
        );
  }

  redirectToDetails(promotionId) {
    this.router.navigate(['/promotions', promotionId, 'details']);
  }
s
  redirectToEdit(promotionId) {
    this.router.navigate(['/promotions', promotionId, 'edit']);
  }

  onClickDelete(record) {
    if (confirm('Are you sure you want to delete this promotion?')) {
      this.promotionService.deleteRecord(record.promotionId)
          .subscribe(
              (data) => {
                this.loadData(true);
              },
              error => {}
          );
    }
  }

}
