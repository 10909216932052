import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { MessageService } from '../message.service';
import { catchError } from 'rxjs/internal/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoPurchaseOrderService extends DataService {

  constructor(httpClient: HttpClient,
              messageService: MessageService) {
    super(httpClient, 'order', messageService);
  }

  getOrderForSubmit(orderId) {
      const myUrl = this.baseUrl + '/' + orderId + '/info';
      return this.httpClient.get(myUrl)
        .pipe(map(
            (data: any) => {
              return data.data;
            }
        ), catchError((errorData: any) => {
          return this.handleError(errorData);
        }));
  }

  confirmDateDeliveryStart(resource, orderId) {
      const myUrl = this.baseUrl + '/' + orderId + '/delivery/date-start/confirm';
      return this.httpClient.post(myUrl, resource)
          .pipe(map((data: any) => {
              // const data = response.json();
              this.displayMessages(data.messages, true);

              this.dataChanged.emit(data.data);
              return data.data;
            }));
  }

  cancelDateDeliveryStart(resource, orderId) {
      const myUrl = this.baseUrl + '/' + orderId + '/delivery/date-start/cancel';
      return this.httpClient.post(myUrl, resource)
          .pipe(map((data: any) => {
              // const data = response.json();
              this.displayMessages(data.messages, true);

              this.dataChanged.emit(data.data);
              return data.data;
          }));
  }

    confirmDateDeliveryEnd(resource, orderId) {
        const myUrl = this.baseUrl + '/' + orderId + '/delivery/date-end/confirm';
        return this.httpClient.post(myUrl, resource)
            .pipe(map((data: any) => {
                // const data = response.json();
                this.displayMessages(data.messages, true);

                this.dataChanged.emit(data.data);
                return data.data;
            }));
    }

    cancelDateDeliveryEnd(resource, orderId) {
        const myUrl = this.baseUrl + '/' + orderId + '/delivery/date-end/cancel';
        return this.httpClient.post(myUrl, resource)
            .pipe(map((data: any) => {
                // const data = response.json();
                this.displayMessages(data.messages, true);

                this.dataChanged.emit(data.data);
                return data.data;
            }));
    }

    confirmOrderCancellation(resource, orderId) {
        const myUrl = this.baseUrl + '/' + orderId + '/cancellation/confirm';
        return this.httpClient.post(myUrl, resource)
            .pipe(map((data: any) => {
                // const data = response.json();
                this.displayMessages(data.messages, true);

                this.dataChanged.emit(data.data);
                return data.data;
            }));
    }

    cancelOrderCancellation(resource, orderId) {
        const myUrl = this.baseUrl + '/' + orderId + '/cancellation/cancel';
        return this.httpClient.post(myUrl, resource)
            .pipe(map((data: any) => {
                // const data = response.json();
                this.displayMessages(data.messages, true);

                this.dataChanged.emit(data.data);
                return data.data;
            }));
    }

    getDateOrders(orderDateFrom, orderDateTo) {
        const myUrl = this.baseUrl + '/date/' + orderDateFrom + '/' + orderDateTo + '/total';
        return this.httpClient.get(myUrl)
            .pipe(map(
                (data: any) => {
                    return data.data;
                }
            ), catchError((errorData: any) => {
                return this.handleError(errorData);
            }));
    }
}



