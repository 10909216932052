import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[app-combo-item]',
  templateUrl: './combo-item.component.html',
  styleUrls: ['./combo-item.component.css']
})
export class ComboItemComponent implements OnInit {

  @Input() combo: any;
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

}
