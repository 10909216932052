import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { PaymentMethodService } from './payment-method.service';

interface PaymentMethod {
  paymentMethodId: number;
  paymentMethodNameEng: string;
  paymentMethodNameVie: string;
  paymentMethodRemark: string;
}

@Injectable()

export class PaymentMethodsResolver implements Resolve<PaymentMethod[]> {
  constructor(private paymentMethodService: PaymentMethodService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentMethod[]> | Promise<PaymentMethod[]> | PaymentMethod[] {
    return this.paymentMethodService.getRecords();
  }
}
