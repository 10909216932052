import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';

import { ComboProductService } from '../combo-product.service';
import { PharmaProductService } from '../../services/pharma-product.service';

@Component({
  selector: 'app-edit-combo-product',
  templateUrl: './edit-combo-product.component.html',
  styleUrls: ['./edit-combo-product.component.css']
})
export class EditComboProductComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;

  products: any = [];
  defaultComboProduct: any;
  comboProduct: any;
  comboId: any;
  comboProductId: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private comboProductService: ComboProductService,
    private productService: PharmaProductService
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((parentParams: Params) => {
      this.comboId = +parentParams['comboId'];

      this.route.params.subscribe((params: Params) => {
        this.comboProductId = +params['comboProductId'];
        this.comboProductService.getRecord(this.comboId, this.comboProductId).subscribe((data: Data) => {
          this.defaultComboProduct = data;
          this.loadProductOtcCovered();
        }, error => {});
      });
    }, parentError => {});
  }

  loadProductOtcCovered() {
    this.productService.getProductOtcCovered()
        .subscribe(
            (products: any[]) => {
              this.products = products;
              let keepGoing = true;
              this.products.forEach(product => {
                if (keepGoing) {
                  if (this.defaultComboProduct.productId * 1 === product.productId * 1) {
                    this.defaultComboProduct.product = product;
                    this.comboProduct = Object.assign({}, this.defaultComboProduct);
                    keepGoing = false;
                  }
                }
              });
            },
            error => {}
        );
  }

  onReset() {
    this.comboProduct = Object.assign({}, this.defaultComboProduct);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['/combos', this.comboId, 'products']);
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      //
    } else {
      const comboProduct = {
        productId: value.product.productId,
        unitPrice: value.unitPrice,
        quantity: value.quantity,
        comboProductRemark: value.comboProductRemark
      };
      this.comboProductService.updateRecord(this.comboId, this.comboProductId, comboProduct)
          .subscribe(
              (data) => {
                this.changesSaved = true;
                this.router.navigate(['/combos', this.comboId, 'products']);
              },
              (error) => {}
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultComboProduct.product !== this.comboProduct.product
        || this.defaultComboProduct.product.unitPrice * 1 !== this.comboProduct.product.unitPrice * 1
        || this.defaultComboProduct.quantity !== this.comboProduct.quantity
        || this.defaultComboProduct.comboProductRemark !== this.comboProduct.comboProductRemark) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }
}
