import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { Observable } from 'rxjs/index';
import { ComboService } from '../combo.service';

@Component({
  selector: 'app-create-combo',
  templateUrl: './create-combo.component.html',
  styleUrls: ['./create-combo.component.css']
})
export class CreateComboComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  today = this.calendar.getToday();
  errors: string[] = [];

  defaultCombo: any = {
    comboSubject: '',
    comboDescription: '',
    comboValidFromDate: this.calendar.getToday(),
    comboValidToDate: this.calendar.getToday(),
    comboActive: 1,
    comboRemark: '',
  };
  combo: any = Object.assign({}, this.defaultCombo);


  constructor(
      private calendar: NgbCalendar,
      private router: Router,
      private route: ActivatedRoute,
      private comboService: ComboService,
  ) { }

  ngOnInit() {
  }

  onReset() {
    this.combo = Object.assign({}, this.defaultCombo);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) { }
    else {
      const dateFormat = 'YYYY-MM-DD';
      const comboParams = {
        comboSubject: value.comboSubject,
        comboDescription: value.comboDescription,
        comboValidFromDate: value.comboValidFromDate ? moment([value.comboValidFromDate.year, value.comboValidFromDate.month - 1, value.comboValidFromDate.day]).format(dateFormat) : null,
        comboValidToDate: value.comboValidToDate ? moment([value.comboValidToDate.year, value.comboValidToDate.month - 1, value.comboValidToDate.day]).format(dateFormat) : null,
        comboRemark: value.comboRemark,
        comboActive: value.comboActive
      }
      this.comboService.createRecord(comboParams).subscribe((data: Data) => {
        this.changesSaved = true;
        this.router.navigate(['../'], { relativeTo: this.route });
      }, (error) => {
        this.errors = error;
      });
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultCombo.comboSubject !== this.combo.comboSubject
        || this.defaultCombo.comboDescription !== this.combo.comboDescription
        || this.defaultCombo.comboValidFromDate !== this.combo.comboValidFromDate
        || this.defaultCombo.comboValidToDate !== this.combo.comboValidToDate
        || this.defaultCombo.comboActive !== this.combo.comboActive) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }

}
