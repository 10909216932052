import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, Data } from '@angular/router';
import { Observable } from 'rxjs/index';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { PromotionSchemeService } from '../promotion-scheme.service';

@Component({
  selector: 'app-edit-promotion-scheme',
  templateUrl: './edit-promotion-scheme.component.html',
  styleUrls: ['./edit-promotion-scheme.component.css']
})
export class EditPromotionSchemeComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;

  defaultPromotionScheme: any;
  promotionScheme: any = [];
  otcSalesAreas: any = [];
  promotionProducts: any = [];
  promotionId: number;
  promotionDetailId: number;
  promotionSchemeId: number;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private promotionSchemeService: PromotionSchemeService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(
        (data: Data) => {
          this.otcSalesAreas = data['otcSalesAreas'];
          this.promotionProducts = data['promotionProducts'];
        }
    );
    this.route.parent.parent.params.subscribe((parentParentParams: Params) => {
      this.promotionId = +parentParentParams['promotionId'];
      this.route.parent.params.subscribe((parentParams: Params) => {
        this.promotionDetailId = +parentParams['promotionDetailId'];
        this.route.params.subscribe((params: Params) => {
          this.promotionSchemeId = +params['promotionSchemeId'];

          this.promotionSchemeService.getRecord(this.promotionDetailId, this.promotionSchemeId)
              .subscribe((data: Data) => {
                this.defaultPromotionScheme = data;
                this.promotionScheme = Object.assign({}, this.defaultPromotionScheme);
              });
        });
      });
    });
  }

  onReset() {
    this.promotionScheme = Object.assign({}, this.defaultPromotionScheme);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['/promotions', this.promotionId, 'details', this.promotionDetailId, 'schemes']);
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {

    } else {
      const promotionScheme = {
        otcSalesAreaId: value.otcSalesAreaId,
        promotionProductId: value.promotionProductId,
        minOrderQuantity: value.minOrderQuantity,
        maxOrderQuantity: value.maxOrderQuantity,
        promotionQuantity: value.promotionQuantity,
        promotionSchemeRemark: value.promotionSchemeRemark,
      };

      this.promotionSchemeService.updateRecord(this.promotionDetailId, this.promotionSchemeId, promotionScheme)
          .subscribe((data) => {
            this.changesSaved = true;
            this.router.navigate(['/promotions', this.promotionId, 'details', this.promotionDetailId, 'schemes']);
          }, (error) => {});
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultPromotionScheme.otcSalesAreaId !== this.promotionScheme.otcSalesAreaId
        || this.defaultPromotionScheme.promotionProductId !== this.promotionScheme.promotionProductId
        || this.defaultPromotionScheme.minOrderQuantity !== this.promotionScheme.minOrderQuantity
        || this.defaultPromotionScheme.maxOrderQuantity !== this.promotionScheme.maxOrderQuantity
        || this.defaultPromotionScheme.promotionQuantity !== this.promotionScheme.promotionQuantity
        || this.defaultPromotionScheme.promotionSchemeRemark !== this.promotionScheme.promotionSchemeRemark) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }

}
