import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { Observable } from 'rxjs/index';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import { PharmaProductService } from '../../services/pharma-product.service';
import { PromotionSchemeService } from '../../promotion-schemes/promotion-scheme.service';
import { PurchaseOrderDetailService } from '../purchase-order-detail.service';

@Component({
  selector: 'app-edit-purchase-order-detail',
  templateUrl: './edit-purchase-order-detail.component.html',
  styleUrls: ['./edit-purchase-order-detail.component.css']
})
export class EditPurchaseOrderDetailComponent implements OnInit, CanComponentDeactivate {

  pageTitle: string = 'Create New Order Detail';
  orderId: number;
  orderDetailId: number;
  changesSaved: boolean = false;
  subscription: Subscription;

  productFilterTypes: any[] = [
    { productFilterTypeId: 0, productFilterTypeName: 'Drugs' },
    { productFilterTypeId: 1, productFilterTypeName: 'Cosmetics' },
    { productFilterTypeId: 2, productFilterTypeName: 'An Nam' },
  ];

  products: any[] = [];
  promotion: any;
  promotionSchemes: any = [];

  defaultOrderDetail: any = {
      productFilterTypeId: '',
      product: '',
      orderQuantity: 1,
      promotionQuantity: null,
      promotionScheme: '',
  };

  orderDetail: any = Object.assign({}, this.defaultOrderDetail);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pharmaProductService: PharmaProductService,
    private promotionSchemeService: PromotionSchemeService,
    private purchaseOrderDetailService: PurchaseOrderDetailService,
  ) { }

  ngOnInit() {
      this.route.parent.params.subscribe(
          (params: Params) => {
              this.orderId = +params['orderId'];
          }
      );

      this.route.params.subscribe(
          (params: Params) => {
              this.orderDetailId = +params['orderDetailId'];
          }
      );
      if (this.orderDetailId) {
          this.subscription = this.purchaseOrderDetailService.getRecord(this.orderId, this.orderDetailId)
              .subscribe(
                  (data) => {
                      this.defaultOrderDetail = data;
                      this.defaultOrderDetail.productFilterTypeId = data.cosmetic;
                      console.log('this.orderDetail yeah yeah 111', this.defaultOrderDetail);
                      this.orderDetail = this.defaultOrderDetail;
                      this.loadProductList();
                  },
                  error => {
                      //
                  }
              );
      }
  }

  loadProductList() {
    this.pharmaProductService.getProductListByGroup(this.orderDetail.productFilterTypeId)
          .subscribe(
              (products: any[]) => {
                  this.products = products;
                  if (this.orderDetailId) {
                      let keepGoing = true;
                      this.products.forEach(product => {
                          if (product.productId === this.orderDetail.productId) {
                              this.orderDetail.product = product;
                              this.loadPromotionSchemesByProduct();
                              keepGoing = false;
                          }
                      });
                  }
               },
              error => {

              }
          );
  }

  loadPromotionSchemesByProduct() {
      this.promotionSchemeService.getPromotionSchemesByProduct(this.orderDetail.product.productId, this.orderId)
          .subscribe((data: Data) => {
                this.promotionSchemes = data;
                let keepGoing = true;
                this.orderDetail.promotionScheme = '';
                this.promotionSchemes.forEach(scheme => {
                    if (keepGoing) {
                        if (this.orderDetail.promotionSchemeId * 1 === scheme.promotionSchemeId * 1) {
                            this.orderDetail.promotionScheme = scheme;
                            keepGoing = false;
                        }
                    }
                });
              },
              error => {}
          );
  }

  calculatePromotionQuantity() {
      this.orderDetail.promotionQuantity = null;
      console.log('this.orderDetail.promotionScheme', this.orderDetail.promotionScheme);
      if (this.orderDetail.promotionScheme && this.orderDetail.orderQuantity) {

          if (this.orderDetail.orderQuantity >= this.orderDetail.promotionScheme.minOrderQuantity) {
              this.orderDetail.promotionQuantity = ((this.orderDetail.orderQuantity * 1) * (this.orderDetail.promotionScheme.promotionQuantity * 1 ))
                  / (this.orderDetail.promotionScheme.maxOrderQuantity * 1);

              console.log('this.orderDetail.promotionQuantity', this.orderDetail.promotionQuantity);
          }
      }
  }

  onClickCancel() {
      this.changesSaved = true;
      this.router.navigate(['../'], {relativeTo: this.route});
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
      // console.log('value', value);
      if (!valid) {
          //
      } else {
          console.log('this.orderDetailId', this.orderDetailId);
          const orderDetail = {
              orderId: this.orderId,
              productId: value.product.productId,
              distributorId: value.product.distributorId,
              orderQuantity: value.orderQuantity,
              promotionSchemeId: value.promotionScheme ? value.promotionScheme.promotionSchemeId : null,
              promotionQuantity: value.promotionQuantity ? value.promotionQuantity : null,
              orderDetailRemark: value.orderDetailRemark,
          };

          console.log('orderDetail bla bla....', orderDetail);
          if (this.orderDetailId) {
              console.log('abc def');
              this.purchaseOrderDetailService.updateRecord(this.orderId, this.orderDetailId, orderDetail)
                  .subscribe(
                      (data) => {
                          this.changesSaved = true;
                          this.router.navigate(['../'], {relativeTo: this.route});
                      },
                      (error) => {}
                  );
          } else {
              console.log('data order detail blallfj af');
              this.purchaseOrderDetailService.createRecord(this.orderId, orderDetail)
                  .subscribe(
                      (data) => {
                          console.log('data order detail', data);
                          this.changesSaved = true;
                          this.router.navigate(['../'], {relativeTo: this.route});
                      },
                      (error) => {}
                  );
          }

      }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if ((this.defaultOrderDetail.productFilterTypeId !== this.orderDetail.productFilterTypeId
          || this.defaultOrderDetail.product !== this.orderDetail.product
          || this.defaultOrderDetail.orderQuantity !== this.orderDetail.orderQuantity
          || this.defaultOrderDetail.promotionScheme !== this.orderDetail.promotionScheme
          || this.defaultOrderDetail.promotionQuantity !== this.orderDetail.promotionQuantity
          || this.defaultOrderDetail.orderDetailRemark !== this.orderDetail.orderDetailRemark) && !this.changesSaved) {
          return confirm('Do you want to leave this page and discard the changes?');
      } else {
          return true;
      }
  }
}
