import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PoPurchaseOrderService } from '../purchase-order.service';

@Component({
  selector: 'app-confirm-purchase-order-delivery-end',
  templateUrl: './confirm-purchase-order-delivery-end.component.html',
  styleUrls: ['./confirm-purchase-order-delivery-end.component.css']
})
export class ConfirmPurchaseOrderDeliveryEndComponent implements OnInit {
  @Input() orderId;
  defaultOrder: any = {
    dateConfirmedDeliveryEnd: null
  };
  order: any = Object.assign({}, this.defaultOrder);
  today = this.calendar.getToday();

  constructor(
      public activeModal: NgbActiveModal,
      private calendar: NgbCalendar,
      private purchaseOrderService: PoPurchaseOrderService,
  ) { }

  ngOnInit() {
    this.getOrder(this.orderId);
  }

  getOrder(orderId) {
    // this.order = [];
    this.purchaseOrderService.getRecord(orderId)
        .subscribe(
            (result: any) => {
              this.order = result;
              this.order.dateConfirmedDeliveryEnd = this.toDate(result.dateConfirmedDeliveryEnd);

              this.defaultOrder = Object.assign({}, this.order);
            },
            error => { }
        );
  }

  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  onConfirmDateDeliveryEnd(order, orderId) {
    const order_update = {
      dateConfirmedDeliveryEnd : order.dateConfirmedDeliveryEnd ? moment([order.dateConfirmedDeliveryEnd.year, order.dateConfirmedDeliveryEnd.month - 1, order.dateConfirmedDeliveryEnd.day]).format('YYYY-MM-DD') : null,
    }
    this.purchaseOrderService.confirmDateDeliveryEnd(order_update, orderId)
        .subscribe(
            (data) => {},
            (error) => {}
        );
  }

  onCancelDateDeliveryEnd(order, orderId) {
    this.purchaseOrderService.cancelDateDeliveryEnd(order, orderId)
        .subscribe(
            (data) => {},
            (error) => {}
        );
  }

  onReset() {
    console.log('this.defaultOrder reset', this.defaultOrder);
    this.order = Object.assign({}, this.defaultOrder);
    console.log('this.order reset', this.order);
  }

}
