import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });
  // private authUrl = 'http://127.0.0.1:8000/oauth/token';
  private authUrl = 'http://td-order-be.r2omm.xyz/oauth/token';
  // private clientSecret = 'imJLonHU3yU7pwPRpJbU74lEGDJMn16KOsNCQDZe';
  private clientSecret = 'ccOWKEIeZtsO5yIXJp1GEYUYxOKCg9cYgdFJNrMf';
  private accessToken = 'access_token_tdvn';
  private refreshToken = 'refresh_token_tdvn';

  constructor(private httpClient: HttpClient,
              private router: Router) {
  }

  get refreshResource() {
    const res = {
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem(this.refreshToken),
      client_id: 2,
      client_secret: this.clientSecret,
      scope: ''
    };
    return res;
  }
  get isAuthenticated() {
    return !!localStorage.getItem(this.accessToken);
  }

  get tokenHeader() {
    const httpOptions = {
      headers: this.headers.append('Authorization', 'Bearer ' + localStorage.getItem(this.accessToken))
    };
    return httpOptions;
  }
  login(account) {
    console.log('logging in...', account);
    const res = Object.assign({
      grant_type: 'password',
      client_id: 2,
      client_secret: this.clientSecret,
      scope: ''
    }, account);
    return this.httpClient.post(this.authUrl, res, {
      headers: this.headers
    })
        .pipe(map((data: any) => {
          // console.log('response', response.json());
          // const data = response.json();
          console.log(data);
          this.authenticate(data);
          this.router.navigate(['/']);
        }));
  }

  logout() {
    localStorage.removeItem(this.accessToken);
    localStorage.removeItem(this.refreshToken);
    console.log('redirecting to login...');
    this.router.navigate(['/auth/login']);

  }

  authenticate(res) {
    console.log('res afd faf', res);
    if (!res.access_token) {
      return;
    }
    localStorage.setItem(this.accessToken, res.access_token);
    localStorage.setItem(this.refreshToken, res.refresh_token);
  }
}
