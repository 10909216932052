import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, Data } from '@angular/router';
import { PromotionService } from '../promotions/promotion.service';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.css']
})
export class PromotionDetailsComponent implements OnInit {

  promotion: any = [];
  promotionId: number;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private promotionService: PromotionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
        (params: Params) => {
          this.promotionId = +params['promotionId'];
          this.promotionService.getRecord(this.promotionId)
              .subscribe(
                  (data) => {
                    this.promotion = data;
                  }
              );
        }
    );
  }

}
