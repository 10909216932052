import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';

import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { CreatePurchaseOrderComponent } from './purchase-orders/create-purchase-order/create-purchase-order.component';

import { PurchaseOrderDetailsComponent } from './purchase-order-details/purchase-order-details.component';
import { PurchaseOrderDetailComponent } from './purchase-order-details/purchase-order-detail/purchase-order-detail.component';
import { EditPurchaseOrderDetailComponent } from './purchase-order-details/edit-purchase-order-detail/edit-purchase-order-detail.component';
import { CreatePurchaseOrderDetailComboComponent } from './purchase-order-details/purchase-order-detail-combo/create-purchase-order-detail-combo/create-purchase-order-detail-combo.component';

import { OrderStatusesResolver } from './services/order-status-resolver.service';
import { PaymentMethodsResolver } from './services/payment-method-resolver.service';
import { OtcSalesAreaResolver } from './services/otc-sales-area-resolver.service';

import { PurchaseOrderDetailStartComponent } from './purchase-order-details/purchase-order-detail-start/purchase-order-detail-start.component';
import { SubmitPurchaseOrderComponent } from './purchase-orders/submit-purchase-order/submit-purchase-order.component';
import { ShowPurchaseOrderComponent } from './purchase-orders/show-purchase-order/show-purchase-order.component';

import { PromotionsComponent } from './promotions/promotions.component';
import { CreatePromotionComponent } from './promotions/create-promotion/create-promotion.component';
import { EditPromotionComponent } from './promotions/edit-promotion/edit-promotion.component';

import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { PromotionDetailComponent } from './promotion-details/promotion-detail/promotion-detail.component';
import { PromotionDetailStartComponent } from './promotion-details/promotion-detail-start/promotion-detail-start.component';
import { CreatePromotionDetailComponent } from './promotion-details/create-promotion-detail/create-promotion-detail.component';
import { EditPromotionDetailComponent } from './promotion-details/edit-promotion-detail/edit-promotion-detail.component';

import { PromotionSchemesComponent } from './promotion-schemes/promotion-schemes.component';
import { CreatePromotionSchemeComponent } from './promotion-schemes/create-promotion-scheme/create-promotion-scheme.component';
import { EditPromotionSchemeComponent } from './promotion-schemes/edit-promotion-scheme/edit-promotion-scheme.component';

import { CombosComponent } from './combos/combos.component';
import { ComboStartComponent } from './combos/combo-start/combo-start.component';
import { ComboComponent } from './combos/combo/combo.component';
import { CreateComboComponent } from './combos/create-combo/create-combo.component';
import { EditComboComponent } from './combos/edit-combo/edit-combo.component';

import { ComboProductsComponent } from './combo-products/combo-products.component';
import { CreateComboProductComponent } from './combo-products/create-combo-product/create-combo-product.component';
import { EditComboProductComponent } from './combo-products/edit-combo-product/edit-combo-product.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { CanDeactivateGuard } from './common/can-deactivate-guard.service';
import { PromotionProductResolver } from './promotion-products/promotion-product-resolver.service';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'auth/login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'orders', component: PurchaseOrdersComponent
      , resolve: { orderStatuses: OrderStatusesResolver } },
  { path: 'orders/create', component: CreatePurchaseOrderComponent
      , resolve: { orderStatuses: OrderStatusesResolver, paymentMethods: PaymentMethodsResolver } },
  { path: 'orders/:orderId/edit', component: CreatePurchaseOrderComponent
    , resolve: { orderStatuses: OrderStatusesResolver, paymentMethods: PaymentMethodsResolver } },
  { path: 'orders/:orderId/submit', component: SubmitPurchaseOrderComponent },
  { path: 'orders/:orderId/show', component: ShowPurchaseOrderComponent },
  { path: 'orders/:orderId/details', component: PurchaseOrderDetailsComponent, children: [
    { path: '', component: PurchaseOrderDetailStartComponent },
    { path: 'create', component: EditPurchaseOrderDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: ':orderDetailId', component: PurchaseOrderDetailComponent },
    { path: ':orderDetailId/edit', component: EditPurchaseOrderDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'create/combo', component: CreatePurchaseOrderDetailComboComponent }
  ]},
  { path: 'orders/:orderId/details/create', component: EditPurchaseOrderDetailComponent },
  { path: 'promotions', component: PromotionsComponent },
  { path: 'promotions/create', component: CreatePromotionComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'promotions/:promotionId/edit', component: EditPromotionComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'promotions/:promotionId/details', component: PromotionDetailsComponent, children: [
    { path: '', component: PromotionDetailStartComponent },
    { path: 'create', component: CreatePromotionDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: ':promotionDetailId', component: PromotionDetailComponent, children: [
      { path: 'schemes', component: PromotionSchemesComponent },
      { path: 'schemes/scheme/create', component: CreatePromotionSchemeComponent, canDeactivate: [CanDeactivateGuard], resolve: { otcSalesAreas: OtcSalesAreaResolver, promotionProducts: PromotionProductResolver }},
      { path: 'schemes/scheme/:promotionSchemeId/edit', component: EditPromotionSchemeComponent, canDeactivate: [CanDeactivateGuard], resolve: { otcSalesAreas: OtcSalesAreaResolver, promotionProducts: PromotionProductResolver }}
    ]},
    { path: ':promotionDetailId/edit', component: EditPromotionDetailComponent, canDeactivate: [CanDeactivateGuard] },
  ]},
  { path: 'combos', component: CombosComponent, children: [
    { path: '', component: ComboStartComponent },
    { path: 'create', component: CreateComboComponent, canDeactivate: [CanDeactivateGuard] },
    { path: ':comboId', component: ComboComponent, children: [
      { path: 'products', component: ComboProductsComponent },
      { path: 'product/create', component: CreateComboProductComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'product/:comboProductId/edit', component: EditComboProductComponent, canDeactivate: [CanDeactivateGuard] }
    ]},
    { path: ':comboId/edit', component: EditComboComponent, canDeactivate: [CanDeactivateGuard] }
  ]},
  { path: 'dashboard', component: DashboardComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
