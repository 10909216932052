import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params, Data } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { PurchaseOrderDetailService } from '../purchase-order-detail.service';

@Component({
  selector: 'app-purchase-order-detail-list',
  templateUrl: './purchase-order-detail-list.component.html',
  styleUrls: ['./purchase-order-detail-list.component.css']
})
export class PurchaseOrderDetailListComponent implements OnInit {

  orderDetails: any[] = [];
  orderId: number;
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private purchaseOrderDetailService: PurchaseOrderDetailService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
        (params: Params) => {
          this.orderId = +params['orderId'];
          const currentUrl = this.router.url;

          if (currentUrl.indexOf('details') >= 0) {

            this.subscription = this.purchaseOrderDetailService.getRecords(this.orderId)
                .subscribe(
                    (data) => {
                      this.orderDetails = data;
                    },
                    error => {
                      //
                    }
                );
          }
        }
    );

    this.purchaseOrderDetailService.dataChanged.subscribe(
        (data: Data) => {
          this.purchaseOrderDetailService.getRecords(this.orderId)
              .subscribe(
                  (orderDetails: any[]) => {
                    this.orderDetails = orderDetails;
                  },
                  error => {
                    //
                  }
              );
        }
    );
  }

}
