import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { PromotionSchemeService } from './promotion-scheme.service';

@Component({
  selector: 'app-promotion-schemes',
  templateUrl: './promotion-schemes.component.html',
  styleUrls: ['./promotion-schemes.component.css']
})
export class PromotionSchemesComponent implements OnInit, OnDestroy {

  promotionSchemes: any[] = [];
  promotionDetailId: number;
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private promotionSchemeService: PromotionSchemeService
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((parentParams: Params) => {
      this.promotionDetailId = +parentParams['promotionDetailId'];
      this.subscription = this.promotionSchemeService.getRecords(this.promotionDetailId)
          .subscribe((data) => {
            this.promotionSchemes = data;
          },
            error => { }
          );
    });
  }

  onClickDelete(record) {
    if (confirm('Are you sure you want to delete this scheme?')) {
      this.promotionSchemeService.deleteRecord(this.promotionDetailId, record.promotionSchemeId)
          .subscribe(
              (data) => {
                this.promotionSchemeService.getRecords(this.promotionDetailId)
                    .subscribe((data) => {
                          this.promotionSchemes = data;
                        }, error => { }
                    );
              },
              error => {
                //
              }
          );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
