import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './common/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateFRParserFormatter } from './common/ngb-date-fr-parser-formatter';

import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { StringToDatePipe } from './common/pipes/string-to-date.pipe';
import { RelativeDatePipe } from './common/pipes/relative-date.pipe';
import { RelativeDateTimePipe } from './common/pipes/relative-date-time.pipe';
import { FilterPipe } from './common/pipes/filter.pipe';

import { OtcCallService } from './services/otc-call.service';
import { PharmaHospitalService } from './services/pharma-hospital.service';
import { PharmaHospitalContactService } from './services/pharma-hospital-contact.service';
import { PharmaProductService } from './services/pharma-product.service';
import { GeoProvinceService } from './services/geo-province.service';
import { GeoAdminUnitService } from './services/geo-admin-unit.service';
import { CustomerDeliveryService } from './services/customer-delivery.service';
import { CustomerDeliveryContactService } from './services/customer-delivery-contact.service';
import { OrderStatusService } from './services/order-status.service';
import { OrderStatusesResolver } from './services/order-status-resolver.service';
import { PaymentMethodService } from './services/payment-method.service';
import { PaymentMethodsResolver } from './services/payment-method-resolver.service';
import { SendEmailService } from './services/send-email.service';
import { OtcSalesAreaService } from './services/otc-sales-area.service';
import { OtcSalesAreaResolver } from './services/otc-sales-area-resolver.service';

import { PurchaseOrderWelcomeComponent } from './purchase-orders/purchase-order-welcome/purchase-order-welcome.component';

import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { CreatePurchaseOrderComponent } from './purchase-orders/create-purchase-order/create-purchase-order.component';
import { PoPurchaseOrderService } from './purchase-orders/purchase-order.service';

import { PurchaseOrderDetailsComponent } from './purchase-order-details/purchase-order-details.component';
import { PurchaseOrderDetailService } from './purchase-order-details/purchase-order-detail.service';
import { EditPurchaseOrderDetailComponent } from './purchase-order-details/edit-purchase-order-detail/edit-purchase-order-detail.component';
import { PromotionProductsComponent } from './promotion-products/promotion-products.component';

import { PromotionProductService } from './promotion-products/promotion-product.service';
import { PromotionProductResolver } from './promotion-products/promotion-product-resolver.service';
import { PromotionSchemesComponent } from './promotion-schemes/promotion-schemes.component';
import { PromotionSchemeService } from './promotion-schemes/promotion-scheme.service';
import { PurchaseOrderDetailListComponent } from './purchase-order-details/purchase-order-detail-list/purchase-order-detail-list.component';
import { PurchaseOrderDetailItemComponent } from './purchase-order-details/purchase-order-detail-list/purchase-order-detail-item/purchase-order-detail-item.component';
import { PurchaseOrderDetailComponent } from './purchase-order-details/purchase-order-detail/purchase-order-detail.component';
import { PurchaseOrderDetailStartComponent } from './purchase-order-details/purchase-order-detail-start/purchase-order-detail-start.component';

import { PromotionsComponent } from './promotions/promotions.component';
import { CreatePromotionComponent } from './promotions/create-promotion/create-promotion.component';
import { EditPromotionComponent } from './promotions/edit-promotion/edit-promotion.component';
import { PromotionService } from './promotions/promotion.service';

import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { PromotionDetailService } from './promotion-details/promotion-detail.service';
import { NgbdSortableHeader } from './common/sortable.directive';
import { SubmitPurchaseOrderComponent } from './purchase-orders/submit-purchase-order/submit-purchase-order.component';
import { ConfirmPurchaseOrderDeliveryStartComponent } from './purchase-orders/confirm-purchase-order-delivery-start/confirm-purchase-order-delivery-start.component';
import { ConfirmPurchaseOrderDeliveryEndComponent } from './purchase-orders/confirm-purchase-order-delivery-end/confirm-purchase-order-delivery-end.component';
import { CancelPurchaseOrderComponent } from './purchase-orders/cancel-purchase-order/cancel-purchase-order.component';
import { ShowPurchaseOrderComponent } from './purchase-orders/show-purchase-order/show-purchase-order.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { RemoveSpaceDirective } from './common/remove-space.directive';
import { CanDeactivateGuard } from './common/can-deactivate-guard.service';
import { PromotionDetailListComponent } from './promotion-details/promotion-detail-list/promotion-detail-list.component';
import { PromotionDetailItemComponent } from './promotion-details/promotion-detail-list/promotion-detail-item/promotion-detail-item.component';
import { CreatePromotionDetailComponent } from './promotion-details/create-promotion-detail/create-promotion-detail.component';
import { PromotionDetailStartComponent } from './promotion-details/promotion-detail-start/promotion-detail-start.component';
import { EditPromotionDetailComponent } from './promotion-details/edit-promotion-detail/edit-promotion-detail.component';
import { PromotionDetailComponent } from './promotion-details/promotion-detail/promotion-detail.component';
import { CreatePromotionSchemeComponent } from './promotion-schemes/create-promotion-scheme/create-promotion-scheme.component';
import { EditPromotionSchemeComponent } from './promotion-schemes/edit-promotion-scheme/edit-promotion-scheme.component';

import { CombosComponent } from './combos/combos.component';
import { ComboListComponent } from './combos/combo-list/combo-list.component';
import { ComboItemComponent } from './combos/combo-list/combo-item/combo-item.component';
import { CreateComboComponent } from './combos/create-combo/create-combo.component';
import { ComboStartComponent } from './combos/combo-start/combo-start.component';
import { ComboService } from './combos/combo.service';
import { ComboComponent } from './combos/combo/combo.component';
import { EditComboComponent } from './combos/edit-combo/edit-combo.component';
import { ComboProductsComponent } from './combo-products/combo-products.component';
import { ComboProductService } from './combo-products/combo-product.service';
import { CreateComboProductComponent } from './combo-products/create-combo-product/create-combo-product.component';
import { EditComboProductComponent } from './combo-products/edit-combo-product/edit-combo-product.component';
import { CreatePurchaseOrderDetailComboComponent } from './purchase-order-details/purchase-order-detail-combo/create-purchase-order-detail-combo/create-purchase-order-detail-combo.component';


@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    LoginComponent,
    HomeComponent,
    PageNotFoundComponent,

    StringToDatePipe,
    RelativeDatePipe,
    RelativeDateTimePipe,
    FilterPipe,
    NgbdSortableHeader,

    PurchaseOrderWelcomeComponent,
    PurchaseOrdersComponent,
    CreatePurchaseOrderComponent,
    PurchaseOrderDetailsComponent,
    EditPurchaseOrderDetailComponent,
    PromotionProductsComponent,
    PromotionSchemesComponent,
    PurchaseOrderDetailListComponent,
    PurchaseOrderDetailItemComponent,
    PurchaseOrderDetailComponent,
    PurchaseOrderDetailStartComponent,
    PromotionDetailsComponent,
    SubmitPurchaseOrderComponent,
    ConfirmPurchaseOrderDeliveryStartComponent,
    ConfirmPurchaseOrderDeliveryEndComponent,
    CancelPurchaseOrderComponent,
    ShowPurchaseOrderComponent,
    PromotionsComponent,
    DashboardComponent,
    CreatePromotionComponent,
    RemoveSpaceDirective,
    EditPromotionComponent,
    PromotionDetailListComponent,
    PromotionDetailItemComponent,
    CreatePromotionDetailComponent,
    PromotionDetailStartComponent,
    EditPromotionDetailComponent,
    PromotionDetailComponent,
    CreatePromotionSchemeComponent,
    EditPromotionSchemeComponent,
    CombosComponent,
    ComboListComponent,
    ComboItemComponent,
    CreateComboComponent,
    ComboStartComponent,
    ComboComponent,
    EditComboComponent,
    ComboProductsComponent,
    CreateComboProductComponent,
    EditComboProductComponent,
    CreatePurchaseOrderDetailComboComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers: [
    OtcCallService, PharmaHospitalService, PharmaHospitalContactService, PharmaProductService, PromotionDetailService,
    PoPurchaseOrderService, PurchaseOrderDetailService, PromotionProductService, PromotionSchemeService,
    GeoProvinceService, GeoAdminUnitService, CustomerDeliveryService, CustomerDeliveryContactService,
    OrderStatusService, OrderStatusesResolver, PaymentMethodService, PaymentMethodsResolver, SendEmailService,
    PromotionService, CanDeactivateGuard, OtcSalesAreaService, OtcSalesAreaResolver, PromotionProductResolver,
    ComboService, ComboProductService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmPurchaseOrderDeliveryStartComponent, ConfirmPurchaseOrderDeliveryEndComponent, CancelPurchaseOrderComponent]
})
export class AppModule { }
