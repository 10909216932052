import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../common/sortable.directive';
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

import { ConfirmPurchaseOrderDeliveryStartComponent } from './confirm-purchase-order-delivery-start/confirm-purchase-order-delivery-start.component';
import { ConfirmPurchaseOrderDeliveryEndComponent } from './confirm-purchase-order-delivery-end/confirm-purchase-order-delivery-end.component';
import { CancelPurchaseOrderComponent } from './cancel-purchase-order/cancel-purchase-order.component';
import { PoPurchaseOrderService } from './purchase-order.service';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  closeResult: string;
  today = this.calendar.getToday();
  defaultOrders: any[] = [];
  orders: any[] = [];

  itemsPerPage = 10;
  totalItems: any;
  page: any = 1;
  previousPage: any;
  sortBy = 'tbl_PoPurchaseOrder.orderCode';
  sortOrder = 'asc';

  emptyFilteredData = {
    orderDateFrom: this.calendar.getToday(),
    orderDateTo: this.calendar.getToday(),
    orderDateFrom_f: '',
    orderDateTo_f: '',
    orderStatusId: '',
    hospitalName: '',
    hospitalAddress: '',
  };
  filteredData = Object.assign({}, this.emptyFilteredData);
  orderPeriodTypes: any[] = [
      { orderPeriodTypeId: 'date', orderPeriodTypeName: 'Order Date' },
      { orderPeriodTypeId: 'range', orderPeriodTypeName: 'Order Date Range' }
  ];
  orderStatuses: any[] = [];

  constructor(
      private calendar: NgbCalendar,
      private modalService: NgbModal,
      private poPurchaseOrderService: PoPurchaseOrderService,
      private route: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit() {
    console.log('this.emptyFilteredData', this.emptyFilteredData);

    this.loadData(true);
    this.poPurchaseOrderService.dataChanged.subscribe(
        (data) => {
          this.loadData(true);
        }
    );
    this.route.data.subscribe(
        (data: Data) => {
          this.orderStatuses = data['orderStatuses'];
        }
    );
  }

  clearSearch() {
    this.filteredData = Object.assign({}, this.emptyFilteredData);
    this.loadData(true);
  }

  loadData(withFilteredData: boolean) {
    const dateFormat = 'YYYY-MM-DD';
    this.filteredData.orderDateFrom_f = moment([this.filteredData.orderDateFrom.year, this.filteredData.orderDateFrom.month - 1, this.filteredData.orderDateFrom.day]).format(dateFormat);
    this.filteredData.orderDateTo_f = moment([this.filteredData.orderDateTo.year, this.filteredData.orderDateTo.month - 1, this.filteredData.orderDateTo.day]).format(dateFormat);

    const conditions = {
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      filter: this.filteredData,
      page: withFilteredData ? 1 : this.page,
      limit: this.itemsPerPage,
    };

    this.poPurchaseOrderService.getPaginatedData(conditions)
        .subscribe(
            (data: any) => {
              this.orders = data.data;
              this.totalItems = data.paginator.total_count;
              console.log('data', data);
            },
            error => {
              //
            }
        );
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData(false);
    }
  }

  trackById(index, item) {
    return item.orderId;
  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting
    if (direction === '') {
      this.orders = this.defaultOrders;
    } else {
      this.orders = [...this.defaultOrders].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  onClickRefresh() {
    this.loadData(true);
  }

  onClickShowInfo(orderId) {
    this.router.navigate(['/orders', orderId, 'show']);
  }
  redirectToDetails(orderId) {
    this.router.navigate(['/orders', orderId, 'details']);
  }

  redirectToEdit(orderId) {
    this.router.navigate(['/orders', orderId, 'edit']);
  }

  redirectToSubmit(orderId) {
    this.router.navigate(['/orders', orderId, 'submit']);
  }

  onClickDelete(record) {
    if (confirm('Are you sure you want to delete this order?')) {
      this.poPurchaseOrderService.deleteRecord(record.orderId)
          .subscribe(
              (data) => {
                this.loadData(true);
              },
              error => {
                //
              }
          );
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openDeliveryStartConfirmationModal(orderId) {
    const modalRef = this.modalService.open(ConfirmPurchaseOrderDeliveryStartComponent);
    modalRef.componentInstance.orderId = orderId;
  }

  openDeliveryEndConfirmationModal(orderId) {
    const modalRef = this.modalService.open(ConfirmPurchaseOrderDeliveryEndComponent);
    modalRef.componentInstance.orderId = orderId;
  }

  openOrderCancellationModal(orderId) {
    const modalRef = this.modalService.open(CancelPurchaseOrderComponent);
    modalRef.componentInstance.orderId = orderId;
  }

  toggleWithProcessingDate(popover, dtSubmittedAdmin: any, dtSubmittedDistributor: any, dateConfirmedDeliveryStart: any,
                           dateConfirmedDeliveryEnd: any, dateConfirmedOrderCancellation: any) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({dtSubmittedAdmin, dtSubmittedDistributor, dateConfirmedDeliveryStart, dateConfirmedDeliveryEnd, dateConfirmedOrderCancellation});
    }
  }
}
