import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { PromotionDetailService } from '../promotion-detail.service';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'app-promotion-detail-list',
  templateUrl: './promotion-detail-list.component.html',
  styleUrls: ['./promotion-detail-list.component.css']
})
export class PromotionDetailListComponent implements OnInit, OnDestroy {

  promotionDetails: any[] = [];
  promotionDetail: any;
  promotionId: number;
  subscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private promotionDetailService: PromotionDetailService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
        (params: Params) => {
          this.promotionId = +params['promotionId'];
          const currentUrl = this.router.url;

          if (currentUrl.indexOf('detail') >= 0) {
            this.subscription = this.promotionDetailService.getRecords(this.promotionId)
                .subscribe(
                    (data) => {
                      this.promotionDetails = data;
                      console.log('this.promotionDetails', this.promotionDetails);
                    },
                    error => {}
                );
          }
        }
    );

    this.promotionDetailService.dataChanged.subscribe(
        (data: Data) => {
          this.promotionDetailService.getRecords(this.promotionId)
              .subscribe(
                  (promotionDetails: any[]) => {
                    this.promotionDetails = promotionDetails;
                    console.log('this.promotionDetails', this.promotionDetails);
                  },
                  error => {}
              );
        }
    );
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

}
