import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Data, Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { CanComponentDeactivate } from '../../common/can-deactivate-guard.service';


import { PharmaProductService } from '../../services/pharma-product.service';
import { ComboProductService } from '../combo-product.service';

@Component({
  selector: 'app-create-combo-product',
  templateUrl: './create-combo-product.component.html',
  styleUrls: ['./create-combo-product.component.css']
})
export class CreateComboProductComponent implements OnInit, CanComponentDeactivate {

  changesSaved: boolean = false;
  products: any = [];
  comboId: number;
  defaultComboProduct: any = {
    product: '',
    unitPrice: '',
    quantity: 1,
    comboProductRemark: '',
  };
  comboProduct: any = Object.assign({}, this.defaultComboProduct);

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private productService: PharmaProductService,
      private comboProductService: ComboProductService,
  ) { }

  ngOnInit() {
    this.loadProductOtcCovered();
    this.route.parent.params.subscribe((parentParams: Params) => {
      this.comboId = +parentParams['comboId'];
    });
  }

  loadProductOtcCovered() {
    this.productService.getProductOtcCovered()
        .subscribe(
            (products: any[]) => {
              this.products = products;
              console.log('this.products create combo product', this.products);
            },
            error => {}
        );
  }

  onReset() {
    this.comboProduct = this.defaultComboProduct;
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['/combos', this.comboId, 'products']);
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      //
    } else {
      const comboProduct = {
        comboId: this.comboId,
        productId: value.product.productId,
        unitPrice: value.unitPrice,
        quantity: value.quantity,
        comboProductRemark: value.comboProductRemark
      };
      this.comboProductService.createRecord(this.comboId, comboProduct)
          .subscribe((data) => {
                this.changesSaved = true;
                this.router.navigate(['/combos', this.comboId, 'products']);
              }, (error) => {}
          );
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.defaultComboProduct.product !== this.comboProduct.product
        || this.defaultComboProduct.product.unitPrice * 1 !== this.comboProduct.product.unitPrice * 1
        || this.defaultComboProduct.quantity !== this.comboProduct.quantity
        || this.defaultComboProduct.comboProductRemark !== this.comboProduct.comboProductRemark) && !this.changesSaved) {
      return confirm('Do you want to leave this page and discard the changes?');
    } else {
      return true;
    }
  }


}
