import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router, Params } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../../common/sortable.directive';
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
import { OtcCallService } from '../../services/otc-call.service';
import { GeoProvinceService } from '../../services/geo-province.service';
import { GeoAdminUnitService } from '../../services/geo-admin-unit.service';
import { PharmaHospitalService } from '../../services/pharma-hospital.service';
import { CustomerDeliveryService } from '../../services/customer-delivery.service';
import { CustomerDeliveryContactService } from '../../services/customer-delivery-contact.service';
import { PoPurchaseOrderService } from './../purchase-order.service';

@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.css']
})
export class CreatePurchaseOrderComponent implements OnInit {

  pageTitle = '';
  changesSaved: boolean = false;
  today = this.calendar.getToday();
  boxSearchStatus = false;

  itemsPerPage = 5;
  totalItems: any;
  page: any = 1;
  previousPage: any;
  sortBy = 'tbl_PoPurchaseOrder.dtCreated';
  sortOrder = 'asc';
  emptyFilteredHospital  = {
    provinceId: '',
    adminUnitId: '',
    hospitalName: ''
  };
  filteredHospital = Object.assign({}, this.emptyFilteredHospital);
  closeResult: string;

  defaultOrder: any = {
      orderDate: this.calendar.getToday(),
      deliveryDateRequested: this.calendar.getToday(),
      provinceId: '',
      adminUnitId: '',
      inCallHospital: '',
      outCallHospitalId: '',
      outCallHospitalName: '',
      outCallHospitalAddress: '',
      hospitalDeliveryId: '',
      deliveryContactId: '',
      orderCode: '',
      orderStatusId: 1,
      paymentMethodId: '',
      isOutCallCustomer: false,
      newDeliveryCreated: false,
      newDeliveryContactCreated: false,
  }

  provinces: any[] = [];
  adminUnits: any[] = [];
  inCallHospitals: any[] = [];
  outCallHospitals: any[] = [];
  deliveries: any[] = [];
  deliveryContacts: any[] = [];
  orderStatuses: any[] = [];
  paymentMethods: any[] = [];

  orderId: number;


  order = Object.assign({}, this.defaultOrder);
  constructor(
      private calendar: NgbCalendar,
      private modalService: NgbModal,
      private otcCallService: OtcCallService,
      private geoProvinceService: GeoProvinceService,
      private geoAdminUnitService: GeoAdminUnitService,
      private pharmaHospitalService: PharmaHospitalService,
      private customerDeliveryService: CustomerDeliveryService,
      private customerDeliveryContactService: CustomerDeliveryContactService,
      private poPurchaseOrderService: PoPurchaseOrderService,
      private route: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit() {
      this.loadCallList();
      this.loadOtcCoveredProvinces();

      this.route.params.subscribe(
          (params: Params) => {
              this.orderId = +params['orderId'];
              console.log('this.orderId: ', this.orderId);
              if (this.orderId) {
                  this.poPurchaseOrderService.getRecord(this.orderId)
                      .subscribe(
                          (data: Data) => {
                              this.pageTitle = 'Edit Selected Order';
                              this.order = data;
                              this.order.orderDate = this.toDate(data.orderDate);
                              this.order.deliveryDateRequested = this.toDate(data.deliveryDateRequested);
                              this.order.newDeliveryCreated = false;
                              this.order.newDeliveryContactCreated = false;

                              this.loadCallList();

                              if (!this.order.callId) {
                                  this.order.isOutCallCustomer  = true;
                                  this.order.outCallHospitalId = this.order.hospitalId;
                                  this.order.outCallHospitalName = this.order.hospitalName;
                                  this.order.outCallHospitalAddress = this.order.hospitalAddress;
                                  this.loadHospitalDeliveries(0, this.order.outCallHospitalId);

                              }
                              this.loadCustomerDeliveryContacts(this.order.hospitalDeliveryId);
                              console.log('this.order edit: ', this.order);

                              this.defaultOrder = Object.assign({}, this.order);
                          },
                          error => {
                              //
                          }
                      );
              } else {
                  this.pageTitle = 'Create New Order';
              }
          }
      );

      this.route.data.subscribe(
          (data: Data) => {
              this.orderStatuses = data['orderStatuses'];
              this.paymentMethods = data['paymentMethods'];
          }
      );
  }

    toDate(dob) {
        if (dob) {
            const [year, month, day] = dob.split('-');
            const obj = { year: parseInt(year), month: parseInt(month), day:
                parseInt(day.split(' ')[0].trim()) };
            return obj;
        }
    }

  loadCallList() {
      if (this.order.orderDate) {
          this.inCallHospitals = [];
          const dateFormat = 'YYYY-MM-DD';
          const vOrderDate = moment([this.order.orderDate.year, this.order.orderDate.month - 1, this.order.orderDate.day]).format(dateFormat);
          console.log('vOrderDate', vOrderDate);

          this.otcCallService.getCallListForOrder(vOrderDate)
              .subscribe(
                  (data) => {
                      this.inCallHospitals = data;

                      // set selected hospital in case editing //
                      if (this.order.callId) {
                          this._setInCallHospitalSelected();
                      }
                  },
                  error => { //
                  }
              );
      }
  }

  _setInCallHospitalSelected() {
      let keepGoing = true;
      this.inCallHospitals.forEach(item => {
          if (this.order.hospitalId === item.hospitalId) {
              this.order.inCallHospital = item;
              keepGoing = false;

              this.loadHospitalDeliveries(this.order.inCallHospital.hospitalId, 0);
          }
      });
  }

  loadOtcCoveredProvinces() {
    this.geoProvinceService.getOtcCoveredProvinces()
        .subscribe(
            (data) => {
              this.provinces = data;
            },
            error => { //
            }
        );
  }

  loadOtcCoveredAdminUnits(provinceId) {
    this.geoAdminUnitService.getOtcCoveredAdminUnits(provinceId)
        .subscribe(
            (data) => {
              this.adminUnits = data;
            },
            error => { //
            }
        );
  }

  loadHospitalDeliveries(iHospitalId, oHosptialId) {
      this.deliveries = [];
      this.deliveryContacts = [];
      const hospitalSelected = this.order.isOutCallCustomer ? oHosptialId : iHospitalId;

      if (hospitalSelected) {
          this.customerDeliveryService.getRecords(hospitalSelected)
              .subscribe(
                  (data) => {
                      this.deliveries = data;
                  },
                  error => { //
                  }
              );
      }
  }

  loadCustomerDeliveryContacts(deliveryId) {
      this.deliveryContacts = [];
      this.customerDeliveryContactService.getRecords(deliveryId)
          .subscribe(
              (data) => {
                  this.deliveryContacts = data;
              },
              error => { //
              }
          );
  }

  loadOtcCoveredHospitals(withFilteredData: boolean) {
    const conditions = {
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      filter: this.filteredHospital,
      page: withFilteredData ? 1 : this.page,
      limit: this.itemsPerPage,
    };

    this.pharmaHospitalService.getPaginatedDataForOrder(conditions)
        .subscribe(
            (data: any) => {
              this.outCallHospitals = data.data;
              this.totalItems = data.paginator.total_count;
              console.log('data', data);
            },
            error => { //
            }
        );
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
        this.previousPage = page;
        this.loadOtcCoveredHospitals(false);
    }
  }

  trackById(index, item) {
    return item.orderId;
  }

  selectOutCallHospital(record) {
      this.order.outCallHospitalId = record.hospitalId;
      this.order.outCallHospitalName = record.hospitalName;
      this.order.outCallHospitalAddress = record.hospitalAddress;
      this.loadHospitalDeliveries(this.order.inCallHospitalId, this.order.outCallHospitalId);
  }

  clearOutCallHospitalSelected() {
      this.order.outCallHospitalId = '';
      this.order.outCallHospitalName = '';
      this.order.outCallHospitalAddress = '';
      this.loadHospitalDeliveries(this.order.inCallHospitalId, this.order.outCallHospitalId);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.loadOtcCoveredHospitals(false);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
  }

  copyCustomerAddress() {
      this.order.newDeliveryAddress = this.order.isOutCallCustomer ? this.order.outCallHospitalAddress : this.order.inCallHospital.hospitalAddress;
  }

  onReset() {
      this.order = Object.assign({}, this.defaultOrder);
  }

  onClickCancel() {
    this.changesSaved = true;
    this.router.navigate(['/orders']);
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }) {
      console.log('value', value);
      if (!valid) {
          //
      } else {

          const dateFormat = 'YYYY-MM-DD';
          const order = {
              callId: value.isOutCallCustomer ? null : value.inCallHospital.callId,
              hospitalId: value.isOutCallCustomer ? this.order.outCallHospitalId : value.inCallHospital.hospitalId,
              orderDate: value.orderDate ? moment([value.orderDate.year, value.orderDate.month - 1, value.orderDate.day]).format(dateFormat) : null,
              deliveryDateRequested: value.deliveryDateRequested ? moment([value.deliveryDateRequested.year, value.deliveryDateRequested.month - 1, value.deliveryDateRequested.day]).format(dateFormat) : null,
              orderStatusId: value.orderStatusId,
              paymentMethodId: value.paymentMethodId,
              hospitalDeliveryId: value.newDeliveryCreated ? null : value.hospitalDeliveryId,
              newDeliveryAddress: value.newDeliveryCreated ? value.newDeliveryAddress : null,
              hospitalDeliveryContactId: value.newDeliveryContactCreated ? null : value.hospitalDeliveryContactId ? value.hospitalDeliveryContactId : null,
              newDeliveryContactPersonName : value.newDeliveryContactCreated ? value.newContactPersonName : null,
              newDeliveryContactNo1: value.newDeliveryContactCreated ? value.newDeliveryContactNo1 : null,
              newDeliveryContactNo2: value.newDeliveryContactCreated ? value.newDeliveryContactNo2 : null,
              newDeliveryContactRemark: value.newDeliveryContactCreated ? value.newDeliveryContactRemark : null,
              orderRemark: value.orderRemark,
              newDeliveryCreated: value.newDeliveryCreated,
              newDeliveryContactCreated: value.newDeliveryContactCreated,
          };

          console.log('order', order);
          if (this.orderId) {
              this.poPurchaseOrderService.updateRecord(this.orderId, order)
                  .subscribe(
                      (data) => {
                          this.changesSaved = true;
                          this.router.navigate(['/orders']);
                      },
                      (error) => {

                      }
                  );
          } else {

              this.poPurchaseOrderService.createRecord(order)
                  .subscribe(
                      (data) => {
                          console.log('data order', data);

                          this.changesSaved = true;
                          this.router.navigate(['/orders']);
                      },
                      (error) => {

                      }
                  );
          }

      }
  }
}
