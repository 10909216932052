import { Component, OnInit } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PoPurchaseOrderService } from '../purchase-orders/purchase-order.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  today = this.calendar.getToday();
  dateOrders: any[];
  emptySearchedData = {
    orderDateFrom: this.calendar.getToday(),
    orderDateTo: this.calendar.getToday(),
  }
  searchedData = Object.assign({}, this.emptySearchedData);
  constructor(
      private calendar: NgbCalendar,
      private purchaseOrder: PoPurchaseOrderService,
  ) { }

  ngOnInit() {
  }

  clearSearch() {
    this.searchedData = Object.assign({}, this.emptySearchedData);
  }

  onClickSearch() {
    const dateFormat = 'YYYY-MM-DD';
    const orderDateFrom = moment([this.searchedData.orderDateFrom.year, this.searchedData.orderDateFrom.month - 1, this.searchedData.orderDateFrom.day]).format(dateFormat);
    const orderDateTo = moment([this.searchedData.orderDateTo.year, this.searchedData.orderDateTo.month - 1, this.searchedData.orderDateTo.day]).format(dateFormat);
    this.purchaseOrder.getDateOrders(orderDateFrom, orderDateTo)
        .subscribe(
            (data) => {
              this.dateOrders = data;
              console.log('this.dateOrders: ', this.dateOrders);
            },
            error => {}
        );
  }

}
